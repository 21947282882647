/*
| Developed by Starton
| Filename : project.endpoint.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { AxiosRequestConfig } from 'axios'
import { EndpointsService } from '@/services/api/endpoints.service'
import { CreateProjectDto, Project, UpdateProjectDto } from '@/modules/project/api/project.dto'
import { PaginatedResponse } from '@/services/api/common.contract'

/*
|--------------------------------------------------------------------------
| Project URLs
|--------------------------------------------------------------------------
*/
export const projectEndpointUrls = {
	index: () => '/project',
	store: () => '/project',
	show: (slug: string) => `/project/${slug}`,
	update: (slug: string) => `/project/${slug}`,
	destroy: (slug: string) => `/project/${slug}`,
	related: (slug: string) => `/project/${slug}/related`,
	approve: (slug: string) => `/admin/project/${slug}/approve`,
	reject: (slug: string) => `/admin/project/${slug}/reject`,
} as const

/*
|--------------------------------------------------------------------------
| Project endpoint
|--------------------------------------------------------------------------
*/
export class ProjectEndpoint extends EndpointsService {
	// [GET] - /project - (AUTH)
	// ----------------------------------------------------------------------------
	public static index(options?: AxiosRequestConfig) {
		return this.createEndpointHelper<PaginatedResponse<Project>>({
			...options,
			url: projectEndpointUrls.index(),
			method: 'GET',
		})
	}

	// [POST] - /project - (AUTH)
	// ----------------------------------------------------------------------------
	public static store(data: CreateProjectDto, options?: AxiosRequestConfig) {
		const formData = new FormData()

		// Add all non-file fields
		formData.append('name', data.name)
		formData.append('description', data.description ?? '')
		formData.append('websiteUrl', data.websiteUrl ?? '')
		formData.append('twitterUrl', data.twitterUrl ?? '')
		formData.append('githubUrl', data.githubUrl ?? '')
		formData.append('linkedinUrl', data.linkedinUrl ?? '')
		formData.append('telegramUrl', data.telegramUrl ?? '')

		// Add tags as array if present
		if (data.tags) {
			data.tags.forEach((tag, index) => {
				formData.append(`tags[${index}]`, tag)
			})
		}

		// Add logo file if present
		if (data.logo) {
			formData.append('logo', data.logo)
		}

		return this.createEndpointHelper<Project>({
			...options,
			url: projectEndpointUrls.store(),
			method: 'POST',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	}

	// [GET] - /project/:slug - (AUTH)
	// ----------------------------------------------------------------------------
	public static show(slug: string, options?: AxiosRequestConfig) {
		return this.createEndpointHelper<Project>({ ...options, url: projectEndpointUrls.show(slug), method: 'GET' })
	}

	// [PUT] - /project/:slug - (AUTH)
	// ----------------------------------------------------------------------------
	public static update(slug: string, data: UpdateProjectDto, options?: AxiosRequestConfig) {
		const formData = new FormData()

		// Add all non-file fields with null fallback
		formData.append('name', data.name ?? '')
		formData.append('description', data.description ?? '')
		formData.append('websiteUrl', data.websiteUrl ?? '')
		formData.append('twitterUrl', data.twitterUrl ?? '')
		formData.append('githubUrl', data.githubUrl ?? '')
		formData.append('linkedinUrl', data.linkedinUrl ?? '')
		formData.append('telegramUrl', data.telegramUrl ?? '')

		// Add tags as JSON string if present
		if ('tags' in data) {
			formData.append('tags', data.tags ? JSON.stringify(data.tags) : '')
		}

		// Add logo file if present in update data
		if ('logo' in data) {
			formData.append('logo', data.logo ?? '')
		}

		return this.createEndpointHelper<Project>({
			...options,
			url: projectEndpointUrls.update(slug),
			method: 'PUT',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
	}

	// [DELETE] - /project/:slug - (AUTH)
	// ----------------------------------------------------------------------------
	public static destroy(slug: string, options?: AxiosRequestConfig) {
		return this.createEndpointHelper<void>({ ...options, url: projectEndpointUrls.destroy(slug), method: 'DELETE' })
	}

	// [GET] - /project/:slug/related - (AUTH)
	// ----------------------------------------------------------------------------
	public static related(slug: string, options?: AxiosRequestConfig) {
		return this.createEndpointHelper<Project[]>({
			...options,
			url: projectEndpointUrls.related(slug),
			method: 'GET',
		})
	}

	// [POST] - /admin/project/:slug/approve - (AUTH)
	// ----------------------------------------------------------------------------
	public static approve(slug: string, options?: AxiosRequestConfig) {
		return this.createEndpointHelper<void>({ ...options, url: projectEndpointUrls.approve(slug), method: 'POST' })
	}

	// [POST] - /admin/project/:slug/reject - (AUTH)
	// ----------------------------------------------------------------------------
	public static reject(slug: string, options?: AxiosRequestConfig) {
		return this.createEndpointHelper<void>({ ...options, url: projectEndpointUrls.reject(slug), method: 'POST' })
	}
}
