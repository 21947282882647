"use strict";
/*
| Developed by Starton
| Filename : array.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.unique = unique;
exports.flatten = flatten;
exports.chunk = chunk;
/**
 * Remove duplicates from an array
 * @param array - The array to remove duplicates from
 * @returns The array without duplicates
 */
function unique(array) {
    return Array.from(new Set(array));
}
/**
 * Flatten an array of arrays
 * @param arrays - The array of arrays to flatten
 * @returns The flattened array
 */
function flatten(arrays) {
    return arrays.reduce((acc, val) => acc.concat(val), []);
}
/**
 * Chunk an array into smaller arrays of a given size
 * @param array - The array to chunk
 * @param size - The size of the chunks
 * @returns The array of chunks
 *
 * @example
 * ```typescript
 * // Example: Processing API requests in batches to respect rate limits
 * const userIds = ['id1', 'id2', 'id3', 'id4', 'id5', 'id6'];
 * const batchSize = 2;
 * const userBatches = chunk(userIds, batchSize);
 *
 * async function processUserBatches() {
 *     for (const batch of userBatches) {
 *         // Process 2 users at a time
 *         await Promise.all(batch.map(id => fetchUserData(id)));
 *         // Wait before processing next batch
 *         await new Promise(resolve => setTimeout(resolve, 1000));
 *     }
 * }
 * ```
 */
function chunk(array, size) {
    return array.reduce((acc, _, i) => (i % size ? acc : [...acc, array.slice(i, i + size)]), []);
}
