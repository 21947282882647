/*
| Developed by Dirupt
| Filename : project.dto.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Attachment, PaginatedParams } from '@/services/api/common.contract'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/

/**
 * Project status
 * - Pending : Project is pending approval
 * - Approved : Project is approved
 * - Rejected : Project is rejected
 */
export enum ProjectStatus {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}

/**
 * Project tags
 */
export enum ProjectTag {
	DEFI = 'DEFI',
	NFT = 'NFT',
	DAO = 'DAO',
	INFRASTRUCTURE = 'INFRASTRUCTURE',
	SOCIAL = 'SOCIAL',
	METAVERSE = 'METAVERSE',
	PRIVACY = 'PRIVACY',
	BRIDGE = 'BRIDGE',
}

/*
|--------------------------------------------------------------------------
| Mappings
|--------------------------------------------------------------------------
*/
export const ProjectStatusMapping = {
	[ProjectStatus.PENDING]: {
		label: 'Pending',
		color: 'warning',
	},
	[ProjectStatus.APPROVED]: {
		label: 'Approved',
		color: 'success',
	},
	[ProjectStatus.REJECTED]: {
		label: 'Rejected',
		color: 'error',
	},
}

export const ProjectTagMapping = {
	[ProjectTag.DEFI]: 'DeFi',
	[ProjectTag.NFT]: 'NFT',
	[ProjectTag.DAO]: 'DAO',
	[ProjectTag.INFRASTRUCTURE]: 'Infrastructure',
	[ProjectTag.SOCIAL]: 'Social',
	[ProjectTag.METAVERSE]: 'Metaverse',
	[ProjectTag.PRIVACY]: 'Privacy',
	[ProjectTag.BRIDGE]: 'Bridge',
}

/*
|--------------------------------------------------------------------------
| DTOs
|--------------------------------------------------------------------------
*/
export interface Project {
	id: string
	slug: string
	name: string
	status: ProjectStatus
	tags: Array<ProjectTag> | null
	description: string | null
	logo: Attachment | null
	websiteUrl: string | null
	twitterUrl: string | null
	githubUrl: string | null
	linkedinUrl: string | null
	telegramUrl: string | null
	// Computed
	isPending: boolean
	isApproved: boolean
	isRejected: boolean
	// Relationships
	userId: string
}

export type CreateProjectDto = Omit<
	Project,
	'id' | 'slug' | 'status' | 'userId' | 'isPending' | 'isApproved' | 'isRejected' | 'logo'
> & {
	logo: File | null
}
export type UpdateProjectDto = Partial<CreateProjectDto>
export type ProjectsIndexParams = PaginatedParams & {
	name?: string
	tags?: Array<ProjectTag>
	status?: Array<ProjectStatus>
}
