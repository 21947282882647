/*
| Developed by Starton
| Filename : ProjectCard.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

'use client'

import { Box, Card, CardContent, CardActions, Typography, Chip, Stack, IconButton } from '@mui/material'
import {
	Language as WebsiteIcon,
	GitHub as GithubIcon,
	Twitter as TwitterIcon,
	LinkedIn as LinkedInIcon,
	Telegram as TelegramIcon,
} from '@mui/icons-material'
import Image from 'next/image'
import { Project, ProjectStatusMapping, ProjectTagMapping } from '@/modules/project/api/project.dto'
import { Utils } from '@starton/utils'
import { UrlsConfig } from '@/config/urls.config'
import Link from 'next/link'
import { useSessionMe } from '@/modules/auth/api/session.hooks'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
	project: Project
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ProjectCard = ({ project }: Props) => {
	const { data: session } = useSessionMe()

	return (
		<Card
			sx={{
				textDecoration: 'none',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				transition: (theme) => theme.transitions.create(['border']),
				border: '1px solid transparent',
				'&:hover': {
					border: (theme) => `1px solid ${theme.palette.primary.main}`,
				},
			}}
			component={Link}
			href={UrlsConfig.webapp.project.details(project.slug)}
		>
			<CardContent sx={{ flexGrow: 1 }}>
				<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mb: 2 }}>
					<Box
						sx={{
							position: 'relative',
							width: 56,
							height: 56,
							borderRadius: 1,
							overflow: 'hidden',
							flexShrink: 0,
							bgcolor: (theme) => theme.palette.background.neutral,
						}}
					>
						{project.logo?.original?.url ? (
							<Image
								src={project.logo.original.url}
								alt={project.name}
								fill
								style={{ objectFit: 'cover' }}
								sizes="56px"
							/>
						) : project.logo?.url ? (
							<Image
								src={project.logo.url}
								alt={project.name}
								fill
								style={{ objectFit: 'cover' }}
								sizes="56px"
							/>
						) : null}
					</Box>
					<Stack spacing={1} sx={{ minWidth: 0, flex: 1 }}>
						<Typography variant="h4" component="h2" noWrap>
							{project.name}
						</Typography>
						{project.tags && (
							<Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
								{project.tags.map((tag) => (
									<Chip key={tag} label={ProjectTagMapping[tag]} size="small" variant="outlined" />
								))}
							</Stack>
						)}
					</Stack>
				</Box>

				{project.description && (
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							WebkitLineClamp: 3,
							WebkitBoxOrient: 'vertical',
						}}
					>
						{Utils.string.truncate(project.description, 100)}
					</Typography>
				)}
			</CardContent>

			<CardActions sx={{ justifyContent: 'space-between', gap: 1 }}>
				<Stack direction="row" spacing={1}>
					{project.websiteUrl && (
						<IconButton component={Link} href={project.websiteUrl} target="_blank" size="small">
							<WebsiteIcon fontSize="small" />
						</IconButton>
					)}
					{project.githubUrl && (
						<IconButton component={Link} href={project.githubUrl} target="_blank" size="small">
							<GithubIcon fontSize="small" />
						</IconButton>
					)}
					{project.twitterUrl && (
						<IconButton component={Link} href={project.twitterUrl} target="_blank" size="small">
							<TwitterIcon fontSize="small" />
						</IconButton>
					)}
					{project.linkedinUrl && (
						<IconButton component={Link} href={project.linkedinUrl} target="_blank" size="small">
							<LinkedInIcon fontSize="small" />
						</IconButton>
					)}
					{project.telegramUrl && (
						<IconButton component={Link} href={project.telegramUrl} target="_blank" size="small">
							<TelegramIcon fontSize="small" />
						</IconButton>
					)}
				</Stack>
				<Stack direction="row" spacing={1}>
					{session?.id === project.userId || session?.isAdmin ? (
						<Chip
							label={ProjectStatusMapping[project.status].label}
							size="small"
							color={project.status === 'APPROVED' ? 'success' : 'warning'}
						/>
					) : null}
					{session?.id === project.userId ? <Chip label="Mine" size="small" color="primary" /> : null}
				</Stack>
			</CardActions>
		</Card>
	)
}
